import 'animate.css';
import React from 'react';import { Link } from 'react-router-dom';
import GlowHoverBoxComponent from '../../components/glowHoverBoxComponent/GlowHoverBoxComponent.js';
import GlowBoxComponent from '../../components/glowBoxComponent/GlowBoxComponent.js';


import image_imageview_21 from '../../../assets/acquaWhite.png';
import image_imageview_3 from '../../../assets/blurredbg.png';
import image_imageview_5 from '../../../assets/helloFeatures.png';
import image_collaborationimg from '../../../assets/collaboration.png';
import image_imageview_11 from '../../../assets/storybook.png';
import image_imageview_73 from '../../../assets/dots.png';
import image_imageview_82 from '../../../assets/sponsors.png';
import image_imageview_57 from '../../../assets/sponsors.png';
import image_globeimg from '../../../assets/globe.png';
import styles from './screen.module.css';

/**
 * Screen component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Screen component.
 */
const Screen = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Screen}>
                
<section custom-agua-item="layer" className={`${styles.main} ${styles.hiddenoverflowx}`}   >
<section custom-agua-item="layer" className={`${styles.galaxybg} ${styles.galaxybg}`}   ></section>
<section custom-agua-item="layer" className={styles.header}   >
<section custom-agua-item="layer" className={styles['layer-228-5baceea8570045fca67c3a84a6bcbe6e']}   >
<p custom-agua-item="text" className={styles['text-95-8cfaebf5ef6e43ab9b35060b25738113']}   >We are launching our beta!</p>
<p custom-agua-item="text" className={styles['text-96-ff0637b13dad4eab995223dbc1f570bb']}   onClick={() => openInNewTab("https://chat.whatsapp.com/B5fArEa3FXo18cuw18h4eG")}>Join now</p>
</section>
<section custom-agua-item="layer" className={styles['layer-229-53ded678cce340be8409af0eda3f5480']}   >
<section custom-agua-item="layer" className={styles['layer-230-d0ae8f4b5a104c71ba051257f05dd7f1']}   >
<img custom-agua-item="imageView" className={styles['imageview-21-78f69d1d417440e2b88db0edd6cc2325']}  alt="imageview-21" src={ image_imageview_21}  />
</section>
<section custom-agua-item="layer" className={styles['layer-231-f9ef7730b5294bbcb34ce628df3a5301']}   >
<p custom-agua-item="text" className={styles['text-91-a49beb47a54f4916ab82e367c50651f0']}   onClick={() => openInNewTab("https://docs.agua.dev/")}>Docs</p>
<p custom-agua-item="text" className={styles['text-92-d8b204606525467184e5e09d190e8e50']}   onClick={() => openInNewTab("https://agua.tools/meetings/santiago-saenz-ariza")}>Join</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.spacertop}   ></section>
<section custom-agua-item="layer" className={styles.hero}   >
<section custom-agua-item="layer" className={styles.heroheader}   >
<section custom-agua-item="layer" className={styles.spacer1}   ></section>
<p custom-agua-item="text" className={styles['text-2-de0ef3ff99d94ff992cc127eaec0bfb7']}   >Introducing</p>
<section custom-agua-item="layer" className={styles.spacer2}   ></section>
</section>
<section custom-agua-item="layer" className={styles.herotitle}   >
<p custom-agua-item="text" className={styles['text-1-59c9b326fe464f5c8c5751b1c61fb97b']}   >Agua</p>
</section>
<section custom-agua-item="layer" className={styles.herosubtitle}   >
<p custom-agua-item="text" className={styles['text-3-952c4fa5f53349569892cdb440d314c4']}   >The world's best tooltik for React.</p>
<p custom-agua-item="text" className={styles['text-14-844722d3149340a299a72ab40e5ba90d']}   >Code at the speed of thought.</p>
</section>
<section custom-agua-item="layer" className={`${styles.herobgimage} animate__animated animate__pulse ${styles.hidden}`}   ></section>
</section>
<section custom-agua-item="layer" className={`${styles.maincta} animate__animated animate__None`}   >
<section custom-agua-item="layer" className={styles.bgfooter}   >
<img custom-agua-item="imageView" className={styles['imageview-3-697acd903bfe4767ac1ba9ca3ff6bf0b']}  alt="imageview-3" src={ image_imageview_3}  />
</section>
<section custom-agua-item="layer" className={styles.titlefooter}   >
<p custom-agua-item="text" className={styles['text-10-b23bfe3f477d4c4f93493312a61ac63b']}   >The future of the</p>
<section custom-agua-item="layer" className={styles['layer-25-37772eeb47c649d8a7633a7db8391808']}   >
<p custom-agua-item="text" className={styles['text-11-b2a93e5e6c204eacbe43825a5d37ffd2']}   >front end</p>
<p custom-agua-item="text" className={styles['text-9-7e5c6237c9b949ad9e8f6ff1fd7e9c5f']}   >starts here.</p>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['ctafooter-orientation']} ${styles['ctafooter']}`}   >
<section custom-agua-item="layer" className={styles.bookcall}   onClick={() => openInNewTab("https://agua.tools/meetings/santiago-saenz-ariza")}>
<p custom-agua-item="text" className={styles['text-12-d06b544ebfd540e3b49b93d46717c92d']}   >Book a demo</p>
<span custom-agua-item="icon" className={styles['icon-1-686f9ffa4b68458b821f54fe7c89a44e']}  >arrow_right_alt</span>
</section>
<section custom-agua-item="layer" className={styles.joincommunity}   onClick={() => openInNewTab("https://chat.whatsapp.com/B5fArEa3FXo18cuw18h4eG")}>
<p custom-agua-item="text" className={styles['text-13-eecb5986468649b79c48fae96e00708a']}   >Join the community</p>
<span custom-agua-item="icon" className={styles['icon-2-c7512d2e234f43d1895abf0f5f6d8385']}  >arrow_right_alt</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.container}   >
<section custom-agua-item="layer" className={styles.features}   >
<section custom-agua-item="layer" className={`${styles.galaxybgtwo} ${styles.galaxybg} ${styles.negmargintop}`}   ></section>
<section custom-agua-item="layer" className={styles.imagefeaturescontainer}   >
<section custom-agua-item="layer" className={styles['layer-238-a084123826ce44a08da5b4692e640631']}   >
<img custom-agua-item="imageView" className={styles['imageview-5-5c960ff1548f424ab71d6da58f0fb006']}  alt="imageview-5" src={ image_imageview_5}  />
</section>
<section custom-agua-item="layer" className={styles['layer-237-766c4a2f4a13457c8a4dfc5319e08454']}   ></section>
</section>
<section custom-agua-item="layer" className={`${styles.featurescontent} ${styles.negmargintop2}`}   >
<section custom-agua-item="layer" className={styles.featuresmaintitle}   >
<p custom-agua-item="text" className={styles['text-19-5996d69b06dc4661926a8a821fa1a661']}   >Meet the features</p>
<p custom-agua-item="text" className={styles['text-20-6f6005ecd0be449699ab9182225dd09a']}   >you will love.</p>
</section>
<section custom-agua-item="layer" className={styles.horizontalcard0}   >
<section custom-agua-item="layer" className={`${styles.cardhor0} ${styles.overflowhidden}`}   >
<GlowHoverBoxComponent width={"2000px"} height={"600px"}  />
</section>
</section>
<section custom-agua-item="layer" className={`${styles['featurecards1-orientation']} ${styles['featurecards1']}`}   >
<section custom-agua-item="layer" className={styles.card1menu}   >
<section custom-agua-item="layer" className={styles['layer-163-ea5a4579043e45f5969fc36cbb468631']}   >
<GlowBoxComponent width={"100%"} height={"100%"} cardBGColor={"#0c0f13"}  />
</section>
<section custom-agua-item="layer" className={styles['layer-164-da6a28df823a420eb39081269d975369']}   >
<img custom-agua-item="imageView" className={styles.collaborationimg}  alt="collaborationimg" src={ image_collaborationimg}  />
<p custom-agua-item="text" className={styles['text-27-35265a6854084a94894c7a1da86d27e4']}   >Easy responsiveness</p>
<p custom-agua-item="text" className={`${styles['text-28-10b1968fafc24af69b91d41fcb7dbf7c']} ${styles['normalwhitespace']}`}   >Our UI allows you to fit designs to differente screen sizes without touching any media query.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.card2menu}   >
<section custom-agua-item="layer" className={styles['layer-250-433545bdefdd42d991a3af6803271662']}   >
<GlowBoxComponent width={"100%"} height={"100%"} cardBGColor={"#0c0f13"}  />
</section>
<section custom-agua-item="layer" className={styles['layer-251-5b547c317a9c45848f2c97a9a08cadc8']}   >
<section custom-agua-item="layer" className={styles['layer-103-97f49bb246714c2f9a5d623b7d98c3e1']}   >
<p custom-agua-item="text" className={styles['text-31-c9a83cff479c43e697fd9977cb025aab']}   >Write code with</p>
<section custom-agua-item="layer" className={styles['layer-104-3f42a8a4563645fda7a0dea1e8e61751']}   >
<p custom-agua-item="text" className={styles['text-32-a0086829842d47708729f44da6f792ce']}   >perfect</p>
<p custom-agua-item="text" className={styles['text-33-a8d197efbcbe47468aa61089f0e01ffe']}   >quality</p>
</section>
<p custom-agua-item="text" className={styles['text-34-edea5b9392aa498e978c3d991ecc76f9']}   >without the pain</p>
</section>
<p custom-agua-item="text" className={styles['text-29-840cd21dd2684d5c87680dcc47b109d8']}   >Level up</p>
<p custom-agua-item="text" className={`${styles['text-30-fa709c781c564ad7a917fd694e87fa0c']} ${styles['normalwhitespace']}`}   >Produce ultra high-quality code, tested by tens of senior React developers and tech leads.</p>
</section>
</section>
<section custom-agua-item="layer" className={styles.card3menu1}   >
<section custom-agua-item="layer" className={styles['layer-178-2f43cbedaf8e4f5481fd0deb94739f6b']}   >
<GlowBoxComponent width={"100%"} height={"100%"} cardBGColor={"#0c0f13"}  />
</section>
<section custom-agua-item="layer" className={styles['layer-179-4955e12c96ec4639966207fa2aa6f555']}   ></section>
</section>
</section>
<section custom-agua-item="layer" className={styles.horizontalcard1}   >
<section custom-agua-item="layer" className={`${styles.cardhor1} ${styles.overflowhidden}`}   >
<GlowHoverBoxComponent width={"2000px"} height={"600px"}  />
</section>
</section>
<section custom-agua-item="layer" className={styles.featurecards2}   >
<section custom-agua-item="layer" className={styles.card3}   >
<section custom-agua-item="layer" className={styles.branch1}   >
<section custom-agua-item="layer" className={styles.prebranch1}   ></section>
<section custom-agua-item="layer" className={styles.innericonglow1}   >
<section custom-agua-item="layer" className={`${styles['layer-605-961fae132b814a0f850ec7c2de849919']} ${styles['glowbgcircle']}`}   ></section>
<section custom-agua-item="layer" className={styles['layer-493-6d5890e16ad340f78b17c2729268b0f8']}   >
<span custom-agua-item="icon" className={styles['icon-13-ecd26cc210c4446193c03ca6cc685028']}  >visibility</span>
</section>
</section>
<section custom-agua-item="layer" className={styles.posbranch1}   ></section>
</section>
<section custom-agua-item="layer" className={styles.card3container}   >
<section custom-agua-item="layer" className={styles.contentcard3}   >
<section custom-agua-item="layer" className={styles.line1card3}   >
<p custom-agua-item="text" className={styles.focusboldcard3}   >Agua Preview</p>
<p custom-agua-item="text" className={styles['text-163-1f493a2b0a644c77bd2c2825a68c29e4']}   >enables</p>
</section>
<p custom-agua-item="text" className={styles.line2card3}   >your team to visualize your</p>
<p custom-agua-item="text" className={styles.line3card3}   >components and their props</p>
<p custom-agua-item="text" className={styles.line4card3}   >the easy way.</p>
<section custom-agua-item="layer" className={styles.didyouknow1}   >
<p custom-agua-item="text" className={styles['text-47-1511a90f2f8f407ab6f879ce22a1a5b6']}   >Did you know?</p>
</section>
<p custom-agua-item="text" className={styles.faster5x}   >6X faster</p>
<p custom-agua-item="text" className={styles.line5card3}   >on-boarding of new hires</p>
<p custom-agua-item="text" className={styles.line6card3}   >with our tools.</p>
</section>
<section custom-agua-item="layer" className={`${styles.card3image} ${styles.overflowhidden}`}   >
<img custom-agua-item="imageView" className={styles['imageview-11-1a0531d15e4748e0a10c485b417a4def']}  alt="imageview-11" src={ image_imageview_11}  />
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.horizontalcard2}   >
<section custom-agua-item="layer" className={`${styles.card4} ${styles.overflowhidden}`}   >
<GlowHoverBoxComponent height={"600px"} width={"2000px"} glowSize={"2000"} glowColor={"#c3068b"}  />
</section>
</section>
<section custom-agua-item="layer" className={styles.featurecards4}   >
<section custom-agua-item="layer" className={styles.bgfadetoblack}   ></section>
<section custom-agua-item="layer" className={`${styles['twocolumnscards-orientation']} ${styles['twocolumnscards']}`}   >
<section custom-agua-item="layer" className={`${styles.card7} ${styles.overflowhidden}`}   >
<GlowHoverBoxComponent width={"1000px"} height={"1000px"}  />
</section>
<section custom-agua-item="layer" className={`${styles.card8} ${styles.overflowhidden}`}   >
<GlowHoverBoxComponent width={"1000px"} height={"1000px"}  />
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['featurecards5-orientation']} ${styles['featurecards5']}`}   >
<section custom-agua-item="layer" className={styles['layer-273-dbd6ee8c5207457bb7d8cf43b6072fc3']}   >
<section custom-agua-item="layer" className={styles.branch3}   >
<section custom-agua-item="layer" className={styles.prebranch3}   ></section>
<section custom-agua-item="layer" className={styles.innericonglow}   >
<section custom-agua-item="layer" className={`${styles['layer-1138-fac66e59597a47278845ce0b62d9df2a']} ${styles['glowbgcircle']}`}   ></section>
<section custom-agua-item="layer" className={styles['layer-1139-334228343227478186313ac409a054ea']}   >
<span custom-agua-item="icon" className={styles['icon-64-58b06f25f78d4939a7f2a1c7a5dc812d']}  >school</span>
</section>
</section>
<section custom-agua-item="layer" className={styles.postbranch3}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-1133-7fb7621a804b4a92b155b60e32577190']}   >
<p custom-agua-item="text" className={styles['text-491-ebe0c899f3ef473881aa156da57d8f84']}   >Document</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-1134-81373cfe93294cb5b06833b77c5e617c']}   >
<section custom-agua-item="layer" className={styles['layer-274-a0a01b43082f4a26b3f03b9620f4d6b5']}   >
<GlowBoxComponent width={"100%"} height={"100%"} cardBGColor={"#0c0f13"} borderRadius={"20px"}  />
</section>
<section custom-agua-item="layer" className={styles['layer-275-1f89ee9830154252acdc424ebd93f708']}   >
<img custom-agua-item="imageView" className={styles['imageview-73-d0f893255aec42c9a1ec48215ae805d6']}  alt="imageview-73" src={ image_imageview_73}  />
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.madeinagua}   >
<section custom-agua-item="layer" className={`${styles.stickymadeinagua} ${styles.blurbg}`}   >
<p custom-agua-item="text" className={styles['text-21-31b5350532034d90a18e788d121b5772']}   >Made in </p>
<p custom-agua-item="text" className={styles['text-22-8b05a5c1aa37422c88cd8a2ee65ac2d9']}   >Agua</p>
</section>
<section custom-agua-item="layer" className={styles.madeincontent}   >
<section custom-agua-item="layer" className={styles.imagescolumn1}   >
<section custom-agua-item="layer" className={`${styles['layer-48-df3e79908bc749d198400b8817fe0281']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-483-fa0e88e0e4f34f26a1259176b11b6835']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4832-4f3b8726448d4691b089489fe07b2cd4']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4833-bb582d4b4f0f4fe1ae6b313f127de88c']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4834-248333696458415c835f3716b0af867e']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4835-19f2eac4867a471d8bcf6d9db117fd2b']} ${styles['growhover']}`}   ></section>
</section>
<section custom-agua-item="layer" className={styles.imagescolumn2}   >
<section custom-agua-item="layer" className={`${styles['layer-482-f3ee3d148bfa4c23b0a24992020ace33']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4836-76c9bfe1c18b44ce8231250d9a1937e2']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4837-aaf655d661974817a563a731f59bcbf1']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4838-65c39d2c35ee44f38e4ef05f51d0256e']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-4839-aa2b97215490413f87fbf995c0ea252e']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48310-97bd0c6ef220447e8ff9a8e24e219b00']} ${styles['growhover']}`}   ></section>
</section>
<section custom-agua-item="layer" className={styles.imagescolumn3}   >
<section custom-agua-item="layer" className={`${styles['layer-4822-6681986d05ac40dca996bc4cb39b0513']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48311-b119136414454c9a876b8e609f19311c']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48312-353d66af51214b3da0e916f7d4267dd1']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48313-76784e55ba824ed69dd31176c073f383']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48314-b5afd3d872764e22b7b09e9c1d4244d6']} ${styles['growhover']}`}   ></section>
<section custom-agua-item="layer" className={`${styles['layer-48315-79360d610be641a089d3a99b9ec83acc']} ${styles['growhover']}`}   ></section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.usersloveus}   >
<section custom-agua-item="layer" className={styles.fadetopurple}   ></section>
<section custom-agua-item="layer" className={styles.sponsorscard}   >
<section custom-agua-item="layer" className={styles.glowsponsors}   >
<GlowBoxComponent width={"100%"} height={"100%"} borderRadius={"20px"} cardBGColor={"#0c0f13"}  />
</section>
<section custom-agua-item="layer" className={`${styles['layer-658-1f741291c7d04ad498c3accc917ea008-orientation']} ${styles['layer-658-1f741291c7d04ad498c3accc917ea008']} ${styles['overflowhidden']}`}   >
<img custom-agua-item="imageView" className={styles['imageview-82-aaff49445ae645d48ccd1ad42f638146']}  alt="imageview-82" src={ image_imageview_82}  />
<section custom-agua-item="layer" className={styles['layer-659-5424eaba04cd43a391bc3db1ec3e85ee']}   >
<section custom-agua-item="layer" className={styles.sponsorstext}   >
<section custom-agua-item="layer" className={styles['layer-501-09ce6dbefef44baab608e4523505f087']}   >
<p custom-agua-item="text" className={styles['text-201-3da03a479a9c466492a5166a225a5ce5']}   >Agua Sponsors</p>
<p custom-agua-item="text" className={styles['text-202-d5eab28e187341e09898d287c8e4a367']}   >lets you monetize,</p>
</section>
<p custom-agua-item="text" className={styles['text-203-6119b965fd33494b9b3d0272be2bd38b']}   >by joining our referrals program</p>
<p custom-agua-item="text" className={styles['text-204-33bde1096256421796484568579acf50']}   >you can start earning money.</p>
</section>
<section custom-agua-item="layer" className={styles.sponsorscta}   onClick={() => openInNewTab("https://agua.tools/meetings/santiago-saenz-ariza")}>
<p custom-agua-item="text" className={styles['text-205-4a9b9ad36cea4b10a4764c0d57dcd136']}   >Monetize with Agua Sponsors</p>
<span custom-agua-item="icon" className={styles['icon-21-1855a799d0bb4ac594f9848d1eea58d2']}  >arrow_right_alt</span>
</section>
</section>
<img custom-agua-item="imageView" className={styles['imageview-57-d9cd3baba0f44deaa8fa608dd8a896df']}  alt="imageview-57" src={ image_imageview_57}  />
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.community1}   >
<section custom-agua-item="layer" className={`${styles['layer-132-0e2a596e690148589d15af21e417d9db']} ${styles['negativemarginleft']}`}   >
<section custom-agua-item="layer" className={`${styles.fadecoverblack} ${styles.normalwhitespace}`}   ></section>
<img custom-agua-item="imageView" className={styles.globeimg}  alt="globeimg" src={ image_globeimg}  />
</section>
<section custom-agua-item="layer" className={styles['layer-765-33e2fd2d38f3456198999635258f79d0']}   >
<section custom-agua-item="layer" className={styles.branch10}   ></section>
<section custom-agua-item="layer" className={styles.iconglow10}   >
<section custom-agua-item="layer" className={`${styles['layer-496-c6c2281596674780a4dcd0646d7402ec']} ${styles['glowbgcircle']}`}   ></section>
<section custom-agua-item="layer" className={styles['layer-495-582fc7a023b44ed39e89ca37f72b9dba']}   >
<span custom-agua-item="icon" className={styles['icon-36-d8966b7596ed4f31a34a3fc33cd436cb']}  >favorite</span>
</section>
</section>
<section custom-agua-item="layer" className={styles.branch10bottom}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-233-90e50c2813b742208e59a1a07c3b4602']}   >
<p custom-agua-item="text" className={`${styles['text-61-9c9a5ec6a8d445b39b81e2b49d8e0083']} ${styles['normalwhitespace']}`}   >Over 1000 developers love</p>
<section custom-agua-item="layer" className={styles['layer-234-e07bff9acd7045529800eed43e0a41a1']}   >
<p custom-agua-item="text" className={styles['text-116-30cc34c621f54beea420476bf64039d2']}   >our</p>
<p custom-agua-item="text" className={styles['text-115-7f93866f75824253b195dda2be5559d8']}   >ecosystem</p>
</section>
<p custom-agua-item="text" className={`${styles['text-117-735fd6031d874acfbe6f6a0b55f534e2']} ${styles['normalwhitespace']}`}   >Whether you’re scaling your startup or just learning how to code, Agua is your home. Join the world’s first React Integrated Developer Platform to build the innovations that empower humanity. Let’s build the future.</p>
<section custom-agua-item="layer" className={styles.joinaguacommunity}   onClick={() => openInNewTab("https://chat.whatsapp.com/B5fArEa3FXo18cuw18h4eG")}>
<p custom-agua-item="text" className={styles['text-63-a84d41f5f167455f957cb5713cebd2f3']}   >Join</p>
<span custom-agua-item="icon" className={styles['icon-6-bf913b1e6e564cc182619ea6a145db7c']}  >arrow_right_alt</span>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.footercontainer}   >
<section custom-agua-item="layer" className={styles['layer-388-2c595456fde34401a2dae65c2ec152be']}   >
<p custom-agua-item="text" className={styles['text-189-649dd2e8035f47c3918150dd6dab16ae']}   >Agua</p>
<p custom-agua-item="text" className={styles['text-190-ae53e63bc09245c4a84d2a3e490a8f1e']}   >Join our developer community</p>
<p custom-agua-item="text" className={`${styles['text-191-c66e1d4a9ab747a1a5c01daf40593425']} ${styles['normalwhitespace']}`}   >Get tips, technical guides, and best practices. </p>
<section custom-agua-item="layer" className={styles.joinfooter}   onClick={() => openInNewTab("https://chat.whatsapp.com/B5fArEa3FXo18cuw18h4eG")}>
<p custom-agua-item="text" className={styles['text-192-f4fb291ab1224c98840c7faa59aa353f']}   >Join</p>
<span custom-agua-item="icon" className={styles['icon-16-341c3725c0d84aa39bbeafb1c4ca3324']}  >arrow_right_alt</span>
</section>
</section>
<section custom-agua-item="layer" className={styles.footerseparator}   ></section>
<section custom-agua-item="layer" className={styles['layer-391-e6c2cabe23a74081bcf3b0ff6fd351af']}   >
<p custom-agua-item="text" className={styles['text-188-d3fc8e03cbbf45c087682e181fffb92b']}   >© 2024 Agua, Inc.</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Screen;
            